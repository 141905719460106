import {
  FILTER_BASE_SCORE,
  FILTER_MEASURE_TEXT, FILTER_OBJECTIVE, FILTER_SCORE_WEIGHT,
  MEASURES_LOADING,
  MEASURES_SUCCESS,
  TOGGLE_MEASURE
} from '../actions/piMeasures'

export const initialState = {
  measuresLoading: false,
  measures: [],
  scoreWeightFilters: ['0', 'Up to 5%', 'Up to 10%'],
  objectiveFilters: ['Coordination Of Care Through Patient Engagement', 'Electronic Prescribing',
    'Health Information Exchange', 'Patient Electronic Access',
    'Protect Patient Health Information', 'Public Health And Clinical Data Registry Reporting'],
  selectedMeasureIds: [],
  selectedCriteriaNumbers: [],
  filters: {
    baseScore: null,
    scoreWeight: null,
    objective: null,
    measureText: null
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case MEASURES_LOADING:
      return {
        ...state,
        measuresLoading: true,
      };
    case MEASURES_SUCCESS:
      return {
        ...state,
        measures: action.measures,
        measuresLoading: false
      };
    case FILTER_BASE_SCORE:
      return {
        ...state,
        filters: {
          ...state.filters,
          baseScore: action.baseScore
        }
      };
    case FILTER_SCORE_WEIGHT:
      return {
        ...state,
        filters: {
          ...state.filters,
          scoreWeight: action.scoreWeight
        }
      };
    case FILTER_OBJECTIVE:
      return {
        ...state,
        filters: {
          ...state.filters,
          objective: action.objective
        }
      };
    case FILTER_MEASURE_TEXT:
      return {
        ...state,
        filters: {
          ...state.filters,
          measureText: action.measureText
        }
      };
    case TOGGLE_MEASURE:
      const newSelected = [...state.selectedMeasureIds];
      const index = newSelected.indexOf(action.measureId);
      if (index !== -1) {
        newSelected.splice(index, 1);
      } else {
        newSelected.push(action.measureId);
      }
      const criteriaList = state.measures.filter((m) => newSelected.includes(m.measureId)).map((m) => m.criteriaNumbers);
      const flattenedList = [].concat(...criteriaList);
      return {
        ...state,
        selectedMeasureIds: newSelected,
        selectedCriteriaNumbers: flattenedList
      };
    default:
      return state;
  }
}

export const MEASURES_LOADING = 'MEASURES_LOADING';
export const MEASURES_SUCCESS = 'MEASURES_SUCCESS';
export const MEASURES_FAILURE = 'MEASURES_FAILURE';
export const FILTER_MEASURE_TYPE = 'FILTER_MEASURE_TYPE';
export const FILTER_MEASURE_SET = 'FILTER_MEASURE_SET';
export const FILTER_MEASURE_TEXT = 'FILTER_MEASURE_TEXT';
export const TOGGLE_MEASURE = 'TOGGLE_MEASURE';

export function loadMeasures() {
  return {
    type: MEASURES_LOADING,
  }
}

export function filterMeasureType(measureType) {
  return {
    type: FILTER_MEASURE_TYPE,
    measureType
  }
}

export function filterMeasureSet(measureSet) {
  return {
    type: FILTER_MEASURE_SET,
    measureSet
  }
}

export function filterMeasureText(measureText) {
  return {
    type: FILTER_MEASURE_TEXT,
    measureText
  }
}

export function toggleMeasure(measureId) {
  return {
    type: TOGGLE_MEASURE,
    measureId
  }
}

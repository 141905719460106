import {
  PRODUCTS_LOADING,
  PRODUCTS_SUCCESS
} from '../actions/products'

export const initialState = {
  productsLoading: false,
  productResults: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case PRODUCTS_LOADING:
      return {
        ...state,
        productsLoading: true,
      };
    case PRODUCTS_SUCCESS:
      return {
        ...state,
        productResults: action.productResults,
        productsLoading: false
      };
    default:
      return state;
  }
}

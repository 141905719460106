import { takeLatest, call, put } from 'redux-saga/effects'
import {fetchProductList} from "../util/firebaseApi";
import {PRODUCTS_FAILURE, PRODUCTS_LOADING, PRODUCTS_SUCCESS} from "../actions/products";

function* loadProducts(action) {
  try {
    const productsResponse = yield call(fetchProductList, action.measureIds, action.criteriaNumbers);
    yield put({ type: PRODUCTS_SUCCESS, productResults: productsResponse })
  } catch (error) {
    yield put({ type: PRODUCTS_FAILURE, error })
  }
}

export default function* loadProductsWatcher() {
  yield takeLatest(PRODUCTS_LOADING, loadProducts)
}

import {combineReducers} from "redux"
import qualityMeasures from './qualityMeasures'
import products from './products'
import piMeasures from './piMeasures'

const reducers = {
  qualityMeasures,
  products,
  piMeasures
};

export default function createReducer() {
  return combineReducers(reducers)
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Input, Row, Select, Steps, Icon} from 'antd';
import QualityMeasureCard from '../components/QualityMeasureCard'
import {
  filterMeasureSet,
  filterMeasureText,
  filterMeasureType,
  loadMeasures,
  toggleMeasure
} from "../actions/qualityMeasures";
import {connect} from 'react-redux'

const Option = Select.Option;
const Search = Input.Search;
const Step = Steps.Step;

class QualityMeasuresPage extends Component {
  static propTypes = {
    loadMeasures: PropTypes.func,
    filterMeasureType: PropTypes.func,
    filterMeasureSet: PropTypes.func,
    filterMeasureText: PropTypes.func,
    toggleMeasure: PropTypes.func,
    measures: PropTypes.array,
    measureSets: PropTypes.object
  };

  componentDidMount() {
    this.props.loadMeasures();
  }

  render() {
    return (
      <div>
        <div className="QualityMeasuresPage">
          <Row className="wizardrow">
            <Col span={18} offset={3} gutter={6}>
              <Steps current={0}>
                <Step title="Quality Measures"/>
                <Step title="Promoting Interoperability"/>
                <Step title="Product Results"/>
              </Steps>
            </Col>
          </Row>
          <Row>
            <Col span="16" offset="4">
              <div class="instructions-title">
                Introduction
              </div>
              <div class="instructions">
                This page is intended to help health care providers who are planning to participate in CMS's
                Merit-base Incentive Payment System (MIPS) select a compatible EHR.  Providers are required to use an
                EHR system which has been certified to a given standard, and specific MIPS measure have different
                certification criteria.  This tool uses data from the <a href="https://chpl.healthit.gov">Certified
                Health IT Product List</a> website to search for certified EHR products which fit a given set of reporting measures.
              </div>
              <div class="instructions">
                To begin, select the 2018 Quality Measures which are applicable to your practice.  Participants must submit
                data for at least 6 measures for the 12-month performance period (January 1 - December 31, 2018).
              </div>
            </Col>
          </Row>
          <Row className="navigationrow">
            <Col offset={19}>
              <Button type="primary" href="/#/promoting-interoperability">
                Next<Icon type="right" />
              </Button>
            </Col>
          </Row>
          <Row gutter={16} align="bottom" className="filterrow">
            <Col span="6" offset="4">
              &nbsp;
              <Search
                placeholder="Search"
                onSearch={this.props.filterMeasureText}
                enterButton
              />
            </Col>
            <Col span="4">
              Measure Type
              <Select style={{width: 200}} onChange={this.props.filterMeasureType} defaultValue="">
                <Option value="">All</Option>
                <Option value="intermediateOutcome">Intermediate Outcome</Option>
                <Option value="outcome">Outcome</Option>
                <Option value="process">Process</Option>
              </Select>
            </Col>
            <Col span="4">
              Specialty Measure Set
              <Select style={{width: 300}} onChange={this.props.filterMeasureSet} defaultValue="">
                <Option value="">All</Option>
                {Object.entries(this.props.measureSets).map(([key, value]) => (
                  <Option value={key} key={key}>{value}</Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Row>
            <Col span="16" offset="4">
              <div>
                {this.props.measures.map((measure) => (
                  <QualityMeasureCard measure={measure} toggleMeasure={this.props.toggleMeasure} key={measure.eMeasureId}
                                      selected={this.props.selectedMeasureIds.includes(measure.eMeasureId)}/>
                ))}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const filterMeasures = (measures, filters) => {
  let results = measures;
  if (filters.measureType) {
    results = results.filter(m => m.measureType === filters.measureType)
  }
  if (filters.measureSet) {
    results = results.filter(m => (m.measureSets && m.measureSets.includes(filters.measureSet)))
  }
  if (filters.measureText) {
    results = results.filter(m =>
      m.title.toLowerCase().indexOf(filters.measureText.toLowerCase()) !== -1 ||
      m.description.toLowerCase().indexOf(filters.measureText.toLowerCase()) !== -1)
  }
  return results;
};

const mapStateToProps = state => {
  return {
    measures: filterMeasures(state.qualityMeasures.measures, state.qualityMeasures.filters),
    measureSets: state.qualityMeasures.measureSets,
    selectedMeasureIds: state.qualityMeasures.selectedMeasureIds
  }
};

const mapDispatchToProps = dispatch => {
  return {
    loadMeasures: () => {
      dispatch(loadMeasures())
    },
    filterMeasureType: (measureType) => {
      dispatch(filterMeasureType(measureType))
    },
    filterMeasureSet: (measureSet) => {
      dispatch(filterMeasureSet(measureSet))
    },
    filterMeasureText: (measureText) => {
      dispatch(filterMeasureText(measureText))
    },
    toggleMeasure: (measureId) => {
      dispatch(toggleMeasure(measureId))
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QualityMeasuresPage)

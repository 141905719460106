import qualityMeasuresWatcher from './qualityMeasures'
import loadProductsWatcher from './products'
import piMeasuresWatcher from './piMeasures'

export default function* sagas() {
  yield [
    qualityMeasuresWatcher(),
    loadProductsWatcher(),
    piMeasuresWatcher()
  ]
}

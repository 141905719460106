import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Table, Row, Col, Steps, Icon, Pagination} from 'antd';
import {
  loadProducts
} from "../actions/products";
import {connect} from 'react-redux'

const { Column } = Table;
const Step = Steps.Step;

class ProductListPage extends Component {
  static propTypes = {
    loadProducts: PropTypes.func
  };

  componentDidMount() {
    this.props.loadProducts(this.props.qualityMeasureIds, this.props.piCriteriaNumbers);
  }

  render() {
    const data = this.props.productResults.map( (p) => {
        const m = {};
        m.developer = p.developer;
        m.product = p.product.name;
        m.version = p.version.version;
        m.certificationDate = new Date(parseInt(p.certificationDate)).toLocaleDateString("en-US",{ year: 'numeric', month: 'long', day: 'numeric' });
        m.productId = p.id;
        return m;
      }
    );

    return (
      <div>
        <div className="ProductListPage">
          <Row className="wizardrow">
            <Col span={18} offset={3} gutter={6}>
              <Steps current={2}>
                <Step title="Quality Measures"/>
                <Step title="Promoting Interoperability"/>
                <Step title="Product Results"/>
              </Steps>
            </Col>
          </Row>
          <Row className="navigationrow">
            <Col offset={3}>
              <Button type="primary" href="/#/promoting-interoperability">
                <Icon type="left" />Previous
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span="18" offset="3">
              <Table dataSource={data} pagination={{ showTotal: (total) => `Total ${total} items` }}>
                <Column
                  title="Developer"
                  key="developer.name"
                  render={(text, record) => (
                    <span>
                  <a target="_blank" href={record.developer.website}>{record.developer.name}</a>
        </span>
                  )}
                />
                <Column
                  title="Product"
                  dataIndex="product"
                  key="product"
                />
                <Column
                  title="Version"
                  dataIndex="version"
                  key="version"
                />
                <Column
                  title="Certification Date"
                  dataIndex="certificationDate"
                  key="certificationDate"
                />
                <Column
                  title="Action"
                  key="action"
                  render={(text, record) => (
                    <span>
                  <a target="_blank" href={"https://chpl.healthit.gov/#/product/" + record.productId}>View Certification Details</a>
        </span>
                  )}
                />
              </Table>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    qualityMeasureIds: state.qualityMeasures.selectedMeasureIds,
    piCriteriaNumbers: state.piMeasures.selectedCriteriaNumbers,
    productResults: state.products.productResults
  }
};

const mapDispatchToProps = dispatch => {
  return {
    loadProducts: (measureIds, criteriaNumbers) => {
      dispatch(loadProducts(measureIds, criteriaNumbers))
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductListPage)

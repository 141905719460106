import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Input, Row, Select, Steps, Icon} from 'antd';
import PiMeasureCard from '../components/PiMeasureCard'
import {
  filterBaseScore,
  filterObjective,
  filterScoreWeight,
  filterMeasureText,
  loadMeasures,
  toggleMeasure
} from "../actions/piMeasures";
import {connect} from 'react-redux'

const Option = Select.Option;
const Search = Input.Search;
const Step = Steps.Step;

class PiMeasuresPage extends Component {
  static propTypes = {
    loadMeasures: PropTypes.func,
    filterBaseScore: PropTypes.func,
    filterObjective: PropTypes.func,
    filterScoreWeight: PropTypes.func,
    filterMeasureText: PropTypes.func,
    toggleMeasure: PropTypes.func,
    measures: PropTypes.array,
    scoreWeightFilters: PropTypes.array,
    objectiveFilters: PropTypes.array
  };

  componentDidMount() {
    this.props.loadMeasures();
  }

  render() {
    return (
      <div>
        <div className="PiMeasuresPage">
          <Row className="wizardrow">
            <Col span={18} offset={3} gutter={6}>
              <Steps current={1}>
                <Step title="Quality Measures"/>
                <Step title="Promoting Interoperability"/>
                <Step title="Product Results"/>
              </Steps>
            </Col>
          </Row>
          <Row>
            <Col span="16" offset="4">
              <div class="instructions-title">
                Promoting Interoperability
              </div>
              <div class="instructions">
                Next, select the 2018 Promoting Interoperability measures applicable to your practice.  Participants
                must submit collected data for 4 or 5 Base Score measures for 90 days or more during 2018.
              </div>
            </Col>
          </Row>
          <Row className="navigationrow">
            <Col offset={4} span={2}>
              <Button type="primary" href="/#/quality-measures">
                <Icon type="left" />Previous
              </Button>
            </Col>
            <Col offset={19}>
              <Button type="primary" href="/#/product-list">
                Next<Icon type="right" />
              </Button>
            </Col>
          </Row>
          <Row gutter={16} align="bottom" className="filterrow">
            <Col span="5" offset="4">
              &nbsp;
              <Search
                placeholder="Search"
                onSearch={this.props.filterMeasureText}
                enterButton
              />
            </Col>
            <Col span="4">
              Required for Base Score
              <Select style={{width: 160}} onChange={this.props.filterBaseScore} defaultValue="">
                <Option value="">All</Option>
                <Option value="Yes">Yes</Option>
                <Option value="No">No</Option>
              </Select>
            </Col>
            <Col span="3">
              Score Weight
              <Select style={{width: 150}} onChange={this.props.filterScoreWeight} defaultValue="">
                <Option value="">All</Option>
                {this.props.scoreWeightFilters.map((value) => (
                  <Option value={value}>{value}</Option>
                ))}
              </Select>
            </Col>
            <Col span="4">
              Objective Name
              <Select style={{width: 250}} onChange={this.props.filterObjective} defaultValue="" dropdownMatchSelectWidth={false}>
                <Option value="">All</Option>
                {this.props.objectiveFilters.map((value) => (
                  <Option value={value}>{value}</Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Row>
            <Col span="16" offset="4">
              <div>
                {this.props.measures.map((measure) => (
                  <PiMeasureCard measure={measure} toggleMeasure={this.props.toggleMeasure} key={measure.measureId}
                                      selected={this.props.selectedMeasureIds.includes(measure.measureId)}/>
                ))}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const filterMeasures = (measures, filters) => {
  let results = measures;
  if (filters.baseScore) {
    results = results.filter(m => m.requiredForBaseScore === filters.baseScore)
  }
  if (filters.scoreWeight) {
    results = results.filter(m => m.performanceScoreWeight === filters.scoreWeight)
  }
  if (filters.objective) {
    results = results.filter(m => m.objectiveName === filters.objective)
  }
  if (filters.measureText) {
    results = results.filter(m =>
      m.name.toLowerCase().indexOf(filters.measureText.toLowerCase()) !== -1 ||
      m.description.toLowerCase().indexOf(filters.measureText.toLowerCase()) !== -1)
  }
  return results;
};

const mapStateToProps = state => {
  return {
    measures: filterMeasures(state.piMeasures.measures, state.piMeasures.filters),
    selectedMeasureIds: state.piMeasures.selectedMeasureIds,
    scoreWeightFilters: state.piMeasures.scoreWeightFilters,
    objectiveFilters: state.piMeasures.objectiveFilters
  }
};

const mapDispatchToProps = dispatch => {
  return {
    loadMeasures: () => {
      dispatch(loadMeasures())
    },
    filterBaseScore: (measureType) => {
      dispatch(filterBaseScore(measureType))
    },
    filterScoreWeight: (measureType) => {
      dispatch(filterScoreWeight(measureType))
    },
    filterObjective: (measureType) => {
      dispatch(filterObjective(measureType))
    },
    filterMeasureText: (measureText) => {
      dispatch(filterMeasureText(measureText))
    },
    toggleMeasure: (measureId) => {
      dispatch(toggleMeasure(measureId))
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PiMeasuresPage)

import 'babel-polyfill';

import React, {Component} from 'react';
import './App.css';
import QualityMeasuresPage from "./containers/QualityMeasuresPage";
import ProductListPage from "./containers/ProductListPage";
import PiMeasuresPage from './containers/PiMeasuresPage'
import {Route, Switch} from "react-router-dom";
import {Layout} from 'antd';

const {Header, Content, Footer} = Layout;

export default class App extends Component {
  render() {
    return (
      <Layout>
        <Header>
          <div className="header">Certified EHRs for Quality Payments</div>
        </Header>
        <Layout>
          <Content>
            <div className="App">
              <Switch>
                <Route path="/quality-measures" component={QualityMeasuresPage}/>
                <Route path="/promoting-interoperability" component={PiMeasuresPage}/>
                <Route path="/product-list" component={ProductListPage}/>
              </Switch>
            </div>
          </Content>
        </Layout>
        <Footer>©2018 Shiro Labs</Footer>
      </Layout>
    );
  }
}

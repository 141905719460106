import { Card, Button } from 'antd';
import React, { Component } from 'react';

const { Meta } = Card;

export default class PiMeasureCard extends Component {
  render() {
    const measure = this.props.measure;
    const addButtonIcon = this.props.selected ? "check" : "plus";
    const addButtonType = this.props.selected ? "primary" : "default";
    const addButtonText = this.props.selected ? "Added To List" : "Add To List";

    return (
      <Card>
        <Meta
          title={measure.name}
        />
        <p/>
        <p>{measure.description}</p>
        <p/>
        <p>Score Weight: {measure.performanceScoreWeight}</p>
        <Button type={addButtonType} icon={addButtonIcon} size="large" onClick={() => this.props.toggleMeasure(measure.measureId)}>{addButtonText}</Button>
      </Card>
    );
  }
}

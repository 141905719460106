import firebase from "firebase";
import "firebase/firestore"

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
};
firebase.initializeApp(config);

const db = firebase.firestore();
db.settings({
  timestampsInSnapshots: true
});

export function fetchQualityMeasures() {
  return db.collection("qualityMeasures").doc("2018").collection("2018Measures").orderBy("title").get().then(function(querySnapshot) {
    const measures = [];
    querySnapshot.forEach(function(doc) {
      measures.push(doc.data());
    });
    return measures;
  });
}

export function fetchMeasureSets() {
  return db.collection("config").doc("bootstrap").get().then(function(doc) {
    return doc.data()['measureSets'];
  });
}

export function fetchProductList(measureIds, criteriaNumbers) {
  let query = db.collection("chplListingSummaries");
  if (measureIds) {
    for (let measureId of measureIds) {
      const measureIdMinusVersion = measureId.substring(0, measureId.indexOf('v'));
      query = query.where('cqmCmsIds.' + measureIdMinusVersion, '==', true);
    }
  }
  if (criteriaNumbers) {
    for (let criteriaNumber of criteriaNumbers) {
      query = query.where(new firebase.firestore.FieldPath('certifiedCriteriaNumbers', criteriaNumber), '==', true);
    }
  }
  return query.where(new firebase.firestore.FieldPath('certifiedCriteriaNumbers','170.315 (a)(1)'), '==', true)
    .get().then(function(querySnapshot) {
      const results = [];
      querySnapshot.forEach(function(doc) {
        results.push(doc.data());
    });
    return results;
  });
}

export function fetchPiMeasures() {
  return db.collection("piMeasures").doc("2018").collection("2018Measures").orderBy("name").get().then(function(querySnapshot) {
    const measures = [];
    querySnapshot.forEach(function(doc) {
      measures.push(doc.data());
    });
    return measures;
  });
}

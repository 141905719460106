export const MEASURES_LOADING = 'piMeasures/MEASURES_LOADING';
export const MEASURES_SUCCESS = 'piMeasures/MEASURES_SUCCESS';
export const MEASURES_FAILURE = 'piMeasures/MEASURES_FAILURE';
export const FILTER_SCORE_WEIGHT = 'piMeasures/FILTER_SCORE_WEIGHT';
export const FILTER_BASE_SCORE = 'piMeasures/FILTER_BASE_SCORE';
export const FILTER_MEASURE_TEXT = 'piMeasures/FILTER_MEASURE_TEXT';
export const FILTER_OBJECTIVE = 'piMeasures/FILTER_OBJECTIVE';
export const TOGGLE_MEASURE = 'piMeasures/TOGGLE_MEASURE';

export function loadMeasures() {
  return {
    type: MEASURES_LOADING,
  }
}

export function filterScoreWeight(scoreWeight) {
  return {
    type: FILTER_SCORE_WEIGHT,
    scoreWeight
  }
}

export function filterBaseScore(baseScore) {
  return {
    type: FILTER_BASE_SCORE,
    baseScore
  }
}

export function filterObjective(objective) {
  return {
    type: FILTER_OBJECTIVE,
    objective
  }
}

export function filterMeasureText(measureText) {
  return {
    type: FILTER_MEASURE_TEXT,
    measureText
  }
}

export function toggleMeasure(measureId) {
  return {
    type: TOGGLE_MEASURE,
    measureId
  }
}

import { takeLatest, call, put } from 'redux-saga/effects'
import {MEASURES_FAILURE, MEASURES_LOADING, MEASURES_SUCCESS} from '../actions/qualityMeasures'
import {fetchQualityMeasures, fetchMeasureSets} from "../util/firebaseApi";

function* loadMeasures() {
  try {
    const measuresResponse = yield call(fetchQualityMeasures);
    const measureSetsResponse = yield call(fetchMeasureSets);
    yield put({ type: MEASURES_SUCCESS, measures: measuresResponse, measureSets: measureSetsResponse })
  } catch (error) {
    yield put({ type: MEASURES_FAILURE, error })
  }
}

export default function* qualityMeasuresWatcher () {
  yield takeLatest(MEASURES_LOADING, loadMeasures)
}


import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import createReducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension'

export default function configureStore(initialState = {}) {
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    createReducer(),
    initialState,
    composeWithDevTools(applyMiddleware(sagaMiddleware)),
  );
  store.runSaga = sagaMiddleware.run;

  return store
}



import { takeLatest, call, put } from 'redux-saga/effects'
import {MEASURES_FAILURE, MEASURES_LOADING, MEASURES_SUCCESS} from '../actions/piMeasures'
import {fetchPiMeasures} from "../util/firebaseApi";

function* loadMeasures() {
  try {
    const measuresResponse = yield call(fetchPiMeasures);
    yield put({ type: MEASURES_SUCCESS, measures: measuresResponse })
  } catch (error) {
    yield put({ type: MEASURES_FAILURE, error })
  }
}

export default function* piMeasuresWatcher () {
  yield takeLatest(MEASURES_LOADING, loadMeasures)
}


export const PRODUCTS_LOADING = 'PRODUCTS_LOADING';
export const PRODUCTS_SUCCESS = 'PRODUCTS_SUCCESS';
export const PRODUCTS_FAILURE = 'PRODUCTS_FAILURE';

export function loadProducts(measureIds, criteriaNumbers) {
  return {
    type: PRODUCTS_LOADING,
    measureIds,
    criteriaNumbers
  }
}

import {
  MEASURES_LOADING,
  MEASURES_FAILURE,
  MEASURES_SUCCESS,
  FILTER_MEASURE_SET,
  FILTER_MEASURE_TYPE, FILTER_MEASURE_TEXT, TOGGLE_MEASURE
} from '../actions/qualityMeasures'

export const initialState = {
  measuresLoading: false,
  measures: [],
  measureSets: {},
  selectedMeasureIds: [],
  filters: {
    measureType: null,
    measureSet: null,
    measureText: null
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case MEASURES_LOADING:
      return {
        ...state,
        measuresLoading: true,
      };
    case MEASURES_SUCCESS:
      return {
        ...state,
        measures: action.measures,
        measureSets: action.measureSets,
        measuresLoading: false
      };
    case FILTER_MEASURE_SET:
      return {
        ...state,
        filters: {
          ...state.filters,
          measureSet: action.measureSet
        }
      };
    case FILTER_MEASURE_TYPE:
      return {
        ...state,
        filters: {
          ...state.filters,
          measureType: action.measureType
        }
      };
    case FILTER_MEASURE_TEXT:
      return {
        ...state,
        filters: {
          ...state.filters,
          measureText: action.measureText
        }
      };
    case TOGGLE_MEASURE:
      const newSelected = [...state.selectedMeasureIds];
      const index = newSelected.indexOf(action.measureId);
      if (index !== -1) {
        newSelected.splice(index, 1);
      } else {
        newSelected.push(action.measureId);
      }
      return {
        ...state,
        selectedMeasureIds: newSelected
      };
    default:
      return state;
  }
}
